.navContainer {
    align-items: center;
    font-family: 'PP Neue Montreal';
    height: 5rem;
    background-color: var(--bg-color);
    transition: all 0.3s linear;
    width: 100vw;
    position: fixed;
    z-index: 9999;
}
  
.navLinks {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: fixed;
    z-index: 999;
    font-size: 14px;
    right: 2rem;
    align-self: flex-start;
    top: 1rem;
}
  
.navLink, a {
    color:  var(--text-color);
    transition: all 0.3s linear;
    text-decoration: none;
}

.navLogo {
    position: fixed;
    z-index: 999;
    justify-content: center;
    top: 1rem;
    left: 1rem;
    width: 6rem;
    font-size: 1rem;
    text-align: right;
    line-height: 1rem;
    display: flex;
}

.navName {
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.navJobtitle {
    margin: 0.2rem 0;
    font-size: 14px;
    display: flex;
    font-family: 'PP Neue Montreal Italic';
}

.navColorChange {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 4rem;
  transition: all 0.3s linear;
  background-color: var(--bg-color);
  bottom: 0;
}

.navDarkMode{
    position: fixed;
    z-index: 999;
    width: 1rem;
    height: 1rem;
    bottom: 2rem;
    right: 3rem;
    border: none;
    background: transparent;
    cursor: pointer;
}

.navSun {
    height: 1rem;
    width: 1rem;
    fill: var(--text-color);
    transition: all 0.3s linear;
    transform: translateY(100px);
}

.navSun--dark {
    transform: translateY(10px);
}

.navMoon {
    height: 1rem;
    width: 1rem;
    fill: var(--text-color);
    transition: all 0.3s linear;
    transform: translateY(-15px);
}

.navMoon--dark {
    transform: translateY(100px);
}

@media screen and (min-width: 1024px) {
  .navContainer {
    align-items: center;
    font-family: 'PP Neue Montreal';
    height: auto;
    background-color: var(--bg-color);
    width: auto;
    position: initial;
    z-index: initial;
  }

  .navColorChange {
    background-color: transparent;
  }
}