
.showcase {
    margin: 0 auto;
    max-width: 160rem;
    display: flex;
    flex-direction: row;
    position: absolute;
    /* background-color: thistle; */
}

.showcaseNav {
    width: 9vw;
    display: flex;
    height: 100vh;
    flex-wrap: nowrap;
}

.showcaseList {
    overflow-y: scroll;
    height: 60vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    top: 20vh;
    position: relative;
    width: 82vw;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.showcaseList::-webkit-scrollbar {
    display: none;
}

.showcaseItem {
    display: flex;
    flex-direction: column;
    padding: 0 0 2rem 0;
}

.showcaseContent {
    display: flex;
    flex-direction: column;
}

.showcaseClient{
    color: var(--text-color);
    transition: all 0.3s linear;
    font-size: 30px;
    z-index: 9999;
    display: flex;
    position: relative;
    justify-content: flex-start;
}

.showcaseTitle{
    color: var(--text-color);
    transition: all 0.3s linear;
    font-size: 2rem;
    z-index: 9999;
    display: flex;
    position: relative;
    justify-content: flex-start;
    margin-right: 2rem;
    width: fit-content;
    text-align: left;
    font-weight: 200;
    line-height: 2rem;
}

.showcaseTitle:hover {
    color: var(--darker-text-color);
    font-family: 'PP Editorial New Italic';
}

.showcaseItemDetail {
  display: flex;
  font-size: 14px;
  color: var(--icon-color);
  position: relative;
  text-align: initial; 
  padding-bottom: 0.5rem;
  font-family: 'PP Neue Montreal';
}

.showcaseCurrent {
    position: fixed;
    font-size: 2px;
    color: var(--text-color);
    transition: all 0.3s linear;
    bottom: 20px;
    right: 20px;
}

.showcaseImage {
    visibility: hidden;
    width: 46vw;
    height: 56vh;
    position: relative;
    object-fit: cover;
    display: none;
    align-self: center;
    aspect-ratio: 16/9;
}

.showcaseImage--visible {
  visibility: visible;
}

.showcaseImage:not([src]) {
  visibility: hidden;
}

.showcaseProgress {
    display: none;
    flex-direction: column;
    width: 80vw;
    top: 15vh;
    left: 10vw;
    position: fixed;
    transform-origin: top left;
    transform: scale(1, 1) rotate(0deg);
    font-family: 'PP Neue Montreal';
}

.showcaseProgressBar {
    background-color: var(--sliderActive-color);
    height: 2px;
    margin-top: 5px;
    clip-path: polygon(0% 0%, 0% 100%, 40% 100%, 40% 0, 60% 0, 60% 100%, 60% 100%, 30% 100%, 100% 100%, 100% 0%);
}

.showcaseProgressCurrent {
    background-color: var(--text-color);
    height: 2px;
    transition: width 0.75s ease-in-out;
}

.showcaseProgressStatus {
    color: var(--darker-text-color);
    width: 4rem;
    height: 14px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: -2px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .showcaseProgress {
    display: flex;
    width: 66vw;
    top: 15vh;
    left: 8rem;
  }

  .showcaseList {
    width: 75vw;
  }

  .showcaseTitle{
    font-size: 4rem;
    line-height: 4rem;
  }

  .showcaseItem {
    padding: 0 1rem 2rem 0;
  }

  .showcaseNav {
    width: 7rem;
  }
}

@media screen and (min-width: 1024px) {
  .showcaseList {
    width: 38vw;
  }

  .showcaseTitle{
    font-size: 3.5rem;
    line-height: 3.5rem;
  }

  .showcaseItem {
    padding: 0 0 2.8rem 0;
  }

  .showcaseImage {
    height: 36vh;
    display: flex;
  }

  .showcaseNav {
    width: 9vw;
  }

  .showcaseProgress {
    display: flex;
    flex-direction: column;
    width: 60vh;
    top: 20vh;
    left: 4.5rem;
    position: fixed;
    transform-origin: top left;
    transform: scale(1, 1) rotate(90deg);
    font-family: 'PP Neue Montreal';
  }

  .showcaseProgressStatus {
    color: var(--darker-text-color);
    width: 1rem;
    height: 14px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-self: center;
    transform: scale(-1);
    rotate: 90deg;
    margin-left: -2rem;
  }
}

/* @media screen and (min-width: 1440px) {
  .showcaseList {
    width: 40vw;
  }
} */
