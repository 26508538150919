.case {
    width: 100vw;
    /* background-color:teal; */
}

.caseContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 160rem;
    margin: 0 auto;
}

.caseHeader {
    display: flex;
    flex-direction: column;
    font-size: 3rem;
    z-index: 200;
    /* margin-top: 7vh; */
  }

@media screen and (min-width: 1024px) {
  .caseHeader {
    font-size: 5rem;
  }
}

.caseHeaderContent {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center both elements horizontally */
  margin-bottom: 4rem;
}

.caseHeaderImageContainer {
  width: 100vw;
  height: 50vh;
  margin-bottom: 15vh;
}

.caseHeaderTitle {
  display: flex;
  z-index: 100;
  position: absolute;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 400;
  width: 100vw;
  top: 15vh;
}

@media screen and (min-width: 1024px) {
  .caseHeaderTitle {
    top: 13vh;
  }

  .caseHeaderContent {
    margin-bottom: auto;
  }
}

/* 

@media screen and (min-width: 1024px) {
  .caseImageContainer, .caseHeaderTitle {
    height: 100vh;
  }
}

*/

.caseContent {
    margin-top: 7vh;
} 

.caseHeaderImage {
    position: absolute;
    object-fit: cover;
    display: flex;
    align-self: center;
    aspect-ratio: 16/9;
}

.caseItemContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    width: 80vw;
    margin: 0 auto;
    padding-top: 2rem;
    text-align: left;
    margin-bottom: 2rem;
}

.caseTitle {
    font-size: 40px;
    color: var(--text-color);
    transition: all 0.3s linear;
    padding-bottom: 10px;
    font-weight: 300;
}

.caseDescription, .caseCopyContainer {
    font-size: 20px;
    font-weight: normal;    
    color: var(--darker-text-color);
    transition: all 0.3s linear;
    width: 80vw;
}

.caseDescription p:first-child {
  margin-top: 5px;
}

.caseCaseItem {
  width: 80vw;
  margin-bottom: 2rem;
}

.caseClient, .caseAgency, 
.caseProductionCompany, .caseDirector, 
.caseFilmingLocations, .caseRole, 
.caseMetadata, .caseType {
    font-family: 'PP Neue Montreal Thin';
    font-size: 18px;
    line-height: 22px;    
    color: var(--darker-text-color);
    transition: all 0.3s linear;
}

.caseImageContainer, .caseVideoContainer, .caseCopyContainer {
  width: 80vw;
  position: relative;
}

.caseCopyContainer {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.caseImage {
  object-fit: contain;
  display: flex;
  align-self: center;
  width: 80vw;
  margin-bottom: 2rem;
}

.caseVideo {
  display: flex;
  width: 80vw;
  object-fit: contain;
  align-self: center;
  margin-bottom: 2rem;
  max-height: 90vh;
}

.caseVideoPlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}

.caseVideoWrapper {
  width: 80vw;
  position: relative;
  display: flex;
  flex-direction: column;
}

.caseVideoContainer, .caseVideoContainer--TwoPart, .caseVideoContainer--ThreePart, .caseVideoContainer--FourPart, .caseVideoContainer--FullWidth {
  display: inline-flex;
  justify-content: space-between;
  position: relative;
  width: 80vw;
}

.caseVideoContainer--TwoPart .caseVideo {
  width: 38.7vw;
}

.caseVideoContainer--ThreePart .caseVideo {
  width: 25vw;
}

.caseVideoContainer--FourPart .caseVideo {
  width: 18vw;
}

.caseButtons {
  display: flex;
  width: 80vw;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.caseBackButton, .caseNextCaseButton {
  display: flex;
  font-size: 14px;
  margin: 2rem 0;
}

.caseVideoContainer .case-twopart {
  display: inline-flex;
  margin-bottom: 0;
  justify-content: space-between;
}

.case-twopart, .case-twopart .caseVideo  {
  width: 39vw;
  padding-right: 2vw;
  display: inline-flex;
  margin-bottom: 0;
}

.case-threepart, .case-threepart .caseVideo   {
  width: 25vw;
  padding-right: 2.5vw;
  display: inline-flex;
  margin-bottom: 0;
}

.case-fourpart, .case-fourpart .caseVideo   {
  width: 18vw;
  padding-right: 2.65vw;
  display: inline-flex;
  margin-bottom: 0;
}

.case-nopadding {
  padding-right: 0;
}

@media screen and (max-width: 767px) {
  .caseDescription {
    margin-bottom: auto;
  }

  .caseItemContainer, .caseVideoContainer, .caseCopyContainer {
    flex-direction: column;
  }

  .caseBackButton, .caseNextCaseButton {
    font-size: 14px;
  }

  .caseCaseItem {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .caseDescription {
    width: 45%;
    margin-bottom: 2rem;
  }
  
  .caseItemContainer, .caseVideoContainer, .caseCopyContainer {
    flex-direction: row;
  }

  .caseButtons {
    margin-bottom: 2rem;
  }

  .caseBackButton, .caseNextCaseButton {
    font-size: 20px;
  }

  .caseDescription {
    margin-bottom: 2rem;
  }

  .caseCaseItem {
    margin-bottom: auto;
    width: 55%;
  }
}

@media screen and (min-width: 1024px) {
  .caseDescription {
    width: 45%;
    margin-bottom: 2rem;
  }
  
  .caseItemContainer, .caseVideoContainer, .caseCopyContainer {
    flex-direction: row;
  }

  .caseButtons {
    margin-bottom: 4rem;
  }

  .caseBackButton, .caseNextCaseButton {
    font-size: 20px;
  }

  .caseDescription {
    margin-bottom: 2rem;
  }
  
  .caseCaseItem {
    margin-bottom: auto;
    width: 55%;
  }
}

.caseVideoContainer--FullWidth {
  flex-direction: column;
}

/* Protect */

input[type="password"] {
  border-radius: 0.5rem;
  height: 1.5rem;
  width: 17rem;
  margin-top: 1rem;
  text-align: center;
  font-family: 'PP Neue Montreal';
  border: 1px solid gray;
}

._1YwH3 button {
  border-radius: 0.4rem;
  height: 1.5rem;
  margin-top: -2rem;
  text-align: center;
  font-family: 'PP Neue Montreal';
  border: 1px solid gray;
  background-color: white;
  width: 5rem;
}

._37_FJ>div {
  padding: 10px;
  width: 23rem;
}
